import { useAuth } from "src/Components/AuthProvider/AuthProvider";
import ErrorPage from "src/Components/views/pages/Error";

function PrivateRoute({ allowedRoles, children }) {
	const { userDetails, loading } = useAuth();
	const userRole = userDetails?.role;

	const hasAccess = allowedRoles.includes(userRole);
	if (loading) {
		return <div>Loading...</div>;
	}

	return hasAccess ? (
		children
	) : (
		<ErrorPage
			title="Unauthorized"
			message="You don't have access to this page"
			statusCode="403"
		/>
	);
}

export default PrivateRoute;
