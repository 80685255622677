import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import bgImage from "../assets/logo.jpg";
import "./Login.css";

import { Card, CardContent, CardHeader, CardTitle } from "../ui/Card";
import { Button } from "../ui/Button";
import { Loader2 } from "lucide-react";
import Nav from "./Nav";
import { useAuth } from "../AuthProvider/AuthProvider";
import { getDefaultRoute } from "../../lib/roleUtils";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const LoginPage = () => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const history = useNavigate();
	const [loading, setLoading] = useState(false);
	const { setIsAuthenticated, getUserInfo } = useAuth();
	const location = useLocation();
	const from = location.state?.from?.pathname;
	useEffect(() => {
		const fetchTokenValidation = async () => {
			const token = localStorage.getItem("access_token");
			if (token !== null && typeof token !== "undefined") {
				let config = {
					method: "get",
					maxBodyLength: Infinity,
					url:
						backendUrl +
						"/api/v1/auth/verifyJwtToken/" +
						localStorage.getItem("access_token"),
				};
				axios
					.request(config)
					.then((response) => {
						if (response.data === true) {
							getUserInfo();
							setIsAuthenticated(true);
							getUserInfo().then((userDetails) => {
								history(from || getDefaultRoute(userDetails.role), {
									replace: true,
								});
							});
						}
					})
					.catch((error) => {
						console.log(error);
					});
			}
		};
		fetchTokenValidation();
	}, [getUserInfo, setIsAuthenticated, history, from]);
	const handleLogin = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const response = await axios.post(
				backendUrl + "/api/v1/auth/authenticate",
				{ email: username, password: password },
			);
			const { access_token, refresh_token } = response.data;
			localStorage.setItem("access_token", access_token);
			localStorage.setItem("refresh_token", refresh_token);
			const userDetails = await getUserInfo();
			setIsAuthenticated(true);
			history(from || getDefaultRoute(userDetails.role), {
				replace: true,
			});
		} catch (error) {
			console.log(error);
			setError(error.response.data.message || "Invalid username or password!");
		}
		setLoading(false);
	};

	return (
		<div
			className="page-wrapper"
			id="main-wrapper"
			data-layout="vertical"
			data-navbarbg="skin6"
			data-sidebartype="full"
			data-sidebar-position="fixed"
			data-header-position="fixed"
			style={{ backgroundColor: "#f5f8fb" }}
		>
			<Nav />
			<div className="flex h-screen items-center justify-center">
				<Card className="w-96 bg-blue-100">
					<CardHeader>
						<CardTitle className="flex flex-col items-center justify-center gap-3">
							<img src={bgImage} width="100" alt="logo" />
							<span
								className="text-center font-bold text-[#111c2d]"
								style={{ color: "#ea3c12" }}
							>
								C H A T Y F Y<br></br>
								<p style={{ fontSize: "10px", color: "black" }}>
									Business Of Conversations
								</p>
							</span>
						</CardTitle>
					</CardHeader>
					<CardContent>
						{error && (
							<div
								className="alert alert-danger alert-dismissible fade show mt-3"
								role="alert"
							>
								{error}
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="alert"
									aria-label="Close"
									onClick={() => setError()}
								></button>
							</div>
						)}
						<form onSubmit={handleLogin}>
							<div className="mb-3">
								<label htmlFor="exampleInputEmail1" className="form-label">
									Email
								</label>
								<input
									type="email"
									className="form-control"
									id="username"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
									aria-describedby="emailHelp"
									required
								/>
							</div>
							<div className="mb-4">
								<label htmlFor="exampleInputPassword1" className="form-label">
									Password
								</label>
								<input
									type="password"
									className="form-control"
									id="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									required
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between mb-4">
								<div className="form-check">
									<input
										className="form-check-input primary"
										type="checkbox"
										value=""
										id="flexCheckChecked"
									/>
									<label
										className="form-check-label text-dark"
										htmlFor="flexCheckChecked"
									>
										Remember this Device
									</label>
								</div>
								{/* <div>
                      <a className="text-primary fw-bold" href="./index.html">
                        Forgot Password ?
                      </a>
                      </div> */}
							</div>
							<div className="flex justify-center">
								<Button
									className="flex justify-center gap-1"
									disabled={loading}
									type="submit"
								>
									{loading && (
										<Loader2 className="mr-2 h-4 w-4 animate-spin transition-all" />
									)}
									Log In
								</Button>
							</div>
						</form>
					</CardContent>
				</Card>
			</div>
		</div>
	);
};

export default LoginPage;
