import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./Components/AuthProvider/AuthProvider";

const ProtectedRoute = () => {
	const { isAuthenticated, loading, setLoading } = useAuth();

	useEffect(() => {
		if (!isAuthenticated) {
			setLoading(false);
		}
	}, [isAuthenticated, setLoading]);

	if (loading && isAuthenticated === null) {
		return <div>Loading...</div>;
	}
	return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
