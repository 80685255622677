import axios from "axios";
import { Check, Eye, EyeOff, X } from "lucide-react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "src/Components/ui/Button";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "src/Components/ui/Card";
import { Input } from "src/Components/ui/Input";
import { Label } from "src/Components/ui/Label";
import logo from "../assets/logo.png";
import Loader from "../views/Loader/Loader";
import { useAuth } from "../AuthProvider/AuthProvider";
import { getDefaultRoute } from "../../lib/roleUtils";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export default function UserSignup() {
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [passwordRequirements, setPasswordRequirements] = useState({
		length: false,
		uppercase: false,
		lowercase: false,
		number: false,
		symbol: false,
	});
	const [loading, setLoading] = useState(true);
	const [passwordsMatch, setPasswordsMatch] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [countdown, setCountdown] = useState(null);
	const navigate = useNavigate();

	const { getUserInfo } = useAuth();

	const { token } = useParams();
	useEffect(() => {
		const validateToken = async () => {
			try {
				const response = await axios.get(
					backendUrl + `/api/v1/auth/verifyJwtToken/${token}`,
				);
				if (response.data !== true) {
					navigate("/error", {
						state: {
							statusCode: 403,
							title: "Forbidden",
							message:
								"The link has expired or has already been used, please contact support.",
						},
					});
				}
			} catch (error) {
				navigate("/error", {
					state: {
						statusCode: 500,
						title: "Internal Server Error",
						message:
							"An error occurred while validating the token, Try again later.",
					},
				});
			} finally {
				setLoading(false); // Update loading state after token validation completes
			}
		};

		validateToken();
	}, [token, navigate]);

	const validatePassword = (value) => {
		setPasswordRequirements({
			length: value.length >= 8,
			uppercase: /[A-Z]/.test(value),
			lowercase: /[a-z]/.test(value),
			number: /[0-9]/.test(value),
			symbol: /[!@#$%^&*]/.test(value),
		});
	};

	useEffect(() => {
		validatePassword(password);
		setPasswordsMatch(password === confirmPassword && password !== "");
	}, [password, confirmPassword]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (Object.values(passwordRequirements).every(Boolean) && passwordsMatch) {
			setIsSubmitting(true);
			try {
				const response = await axios.post(
					backendUrl + `/api/v1/auth/activateUser/${token}`,
					{ password, confirmPassword },
				);
				if (response.status === 200) {
					localStorage.setItem("access_token", response.data.access_token);
					localStorage.setItem("refresh_token", response.data.refresh_token);
					const userDetails = await getUserInfo();
					const page = getDefaultRoute(userDetails.role);
					toast.success(
						`Password set successfully! Redirecting to ${page.split("/")[page.split("/").length - 1]}...`,
					);
					setCountdown(5);
					const timer = setInterval(() => {
						setCountdown((prev) => {
							if (prev <= 1) {
								clearInterval(timer);
								navigate(page);
								return 0;
							}
							return prev - 1;
						});
					}, 1000);
				}
			} catch (error) {
				console.error("Error setting password:", error.response.data);
				toast.error("Error setting password:" + error.response.data.message);
			} finally {
				setIsSubmitting(false);
			}
		} else {
			toast.error(
				"Please meet all password requirements and ensure passwords match",
			);
		}
	};

	const RequirementIcon = ({ met }) =>
		met ? (
			<Check className="h-4 w-4 text-green-500" />
		) : (
			<X className="h-4 w-4 text-red-500" />
		);

	return (
		<div className="flex h-screen items-center justify-center">
			{loading ? (
				<Loader />
			) : (
				<Card className="mx-auto w-full max-w-md border-blue-500">
					<CardHeader className="space-y-1 bg-blue-50">
						<div className="mb-4 flex justify-center">
							<img src={logo} alt="Logo" className="h-24 object-contain" />
						</div>
						<CardTitle className="text-center text-2xl font-bold text-blue-700">
							Set Your Password
						</CardTitle>
						<CardDescription className="text-center text-blue-600">
							Choose a strong password to secure your account
						</CardDescription>
					</CardHeader>
					<CardContent className="pt-6">
						<form onSubmit={handleSubmit}>
							<div className="space-y-4">
								<div className="space-y-2">
									<Label htmlFor="password" className="text-blue-700">
										Password
									</Label>
									<div className="relative">
										<Input
											id="password"
											type={showPassword ? "text" : "password"}
											value={password}
											onChange={(e) => setPassword(e.target.value)}
											required
											className="border-blue-300 pr-10 focus:border-blue-500 focus:ring-blue-500"
										/>
										<Button
											type="button"
											variant="ghost"
											size="icon"
											className="absolute right-2 top-1/2 -translate-y-1/2 text-blue-500 hover:text-blue-700"
											onClick={() => setShowPassword(!showPassword)}
										>
											{showPassword ? (
												<EyeOff className="h-4 w-4" />
											) : (
												<Eye className="h-4 w-4" />
											)}
											<span className="sr-only">
												{showPassword ? "Hide password" : "Show password"}
											</span>
										</Button>
									</div>
								</div>
								<div className="space-y-2">
									<Label htmlFor="confirm-password" className="text-blue-700">
										Confirm Password
									</Label>
									<div className="relative">
										<Input
											id="confirm-password"
											type={showConfirmPassword ? "text" : "password"}
											value={confirmPassword}
											onChange={(e) => setConfirmPassword(e.target.value)}
											required
											className="border-blue-300 pr-10 focus:border-blue-500 focus:ring-blue-500"
										/>
										<Button
											type="button"
											variant="ghost"
											size="icon"
											className="absolute right-2 top-1/2 -translate-y-1/2 text-blue-500 hover:text-blue-700"
											onClick={() =>
												setShowConfirmPassword(!showConfirmPassword)
											}
										>
											{showConfirmPassword ? (
												<EyeOff className="h-4 w-4" />
											) : (
												<Eye className="h-4 w-4" />
											)}
											<span className="sr-only">
												{showConfirmPassword
													? "Hide confirm password"
													: "Show confirm password"}
											</span>
										</Button>
									</div>
								</div>
								<div className="space-y-2 rounded-md bg-blue-50 p-4">
									<h3 className="mb-2 text-sm font-semibold text-blue-700">
										Password Requirements:
									</h3>
									<ul className="space-y-1">
										<li className="flex items-center">
											<RequirementIcon met={passwordRequirements.length} />
											<span className="ml-2 text-sm text-blue-600">
												At least 8 characters
											</span>
										</li>
										<li className="flex items-center">
											<RequirementIcon met={passwordRequirements.uppercase} />
											<span className="ml-2 text-sm text-blue-600">
												One uppercase letter
											</span>
										</li>
										<li className="flex items-center">
											<RequirementIcon met={passwordRequirements.lowercase} />
											<span className="ml-2 text-sm text-blue-600">
												One lowercase letter
											</span>
										</li>
										<li className="flex items-center">
											<RequirementIcon met={passwordRequirements.number} />
											<span className="ml-2 text-sm text-blue-600">
												One number
											</span>
										</li>
										<li className="flex items-center">
											<RequirementIcon met={passwordRequirements.symbol} />
											<span className="ml-2 text-sm text-blue-600">
												One symbol (!@#$%^&*)
											</span>
										</li>
										<li className="flex items-center">
											<RequirementIcon met={passwordsMatch} />
											<span className="ml-2 text-sm text-blue-600">
												Passwords match
											</span>
										</li>
									</ul>
								</div>
							</div>
							<Button
								type="submit"
								className="mt-6 w-full bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50"
								disabled={
									!Object.values(passwordRequirements).every(Boolean) ||
									!passwordsMatch ||
									isSubmitting
								}
							>
								{isSubmitting ? (
									<div className="flex items-center justify-center">
										<span className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent" />
										Setting password...
									</div>
								) : countdown ? (
									<div className="flex items-center justify-center">
										<Check className="mr-2 h-4 w-4" />
										Redirecting in {countdown}s...
									</div>
								) : (
									"Set Password"
								)}
							</Button>
						</form>
					</CardContent>
					<CardFooter className="flex items-center justify-center bg-blue-50 py-4">
						<p className="w-full text-center text-sm text-blue-600">
							Ensure all requirements are met before submitting
						</p>
					</CardFooter>
				</Card>
			)}
		</div>
	);
}
