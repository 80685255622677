import { MessageSquare, UserCircle, UserCog } from "lucide-react";
import React from "react";
const UserManagement = React.lazy(
	() => import("./views/Settings/UserManagement"),
);

const CustomMessagesSettings = React.lazy(
	() => import("./views/Settings/CustomMessageSettings"),
);

const AccountSettings = React.lazy(
	() => import("./views/Settings/AccountSettings"),
);

const EmbeddedSignUp = React.lazy(
	() => import("./views/EmbeddedSignUp/EmbeddedSignup"),
);

const Schedules = React.lazy(() => import("./views/Stepper/Stepper"));

const CustomerData = React.lazy(
	() => import("./views/CustomerData/CustomerData"),
);

const UploadUsers = React.lazy(() => import("./views/UploadUsers/UploadUsers"));

const Charts = React.lazy(() => import("./views/ChartComponent/Chart"));

const Scheduling = React.lazy(() => import("./views/Scheduling/Scheduling"));

const EditSchedule = React.lazy(
	() => import("./views/EditStepper/EditStepper"),
);

const CsvHeaders = React.lazy(() => import("./views/CSVFields/DynamicForm"));

const MessageTemplate = React.lazy(
	() => import("./views/MessageTemplates/MessageTemplateModern"),
);

const ViewMessageTemplate = React.lazy(
	() => import("./views/MessageTemplates/ViewMessageTemplate"),
);

const CreateMessageTemplate = React.lazy(
	() => import("./views/CustomTemplate/CreateTemplate"),
);
// const EditMessageTemplate = React.lazy(
// 	() => import("./views/CustomTemplate/EditTemplate"),
// );

const ScheduleFlow = React.lazy(
	() => import("./views/ScheduleFlows/ScheduleFlows"),
);

const CreateScheduleFlow = React.lazy(
	() => import("./views/ScheduleFlows/CreateScheduleFlow/CreateScheduleFlow"),
);

const AssignUsers = React.lazy(
	() => import("./views/ScheduleFlows/AssignUsers/AssignUsers"),
);

const AssignFlow = React.lazy(
	() => import("./views/ScheduleFlows/AssignUsers/AssignFlow"),
);
const AssignedFlows = React.lazy(
	() => import("./views/ScheduleFlows/AssignedFlows/AssignedFlows"),
);

const EditAssignFlow = React.lazy(
	() => import("./views/ScheduleFlows/EditFlows/EditScheduleFlow"),
);

const Chat = React.lazy(() => import("./chat/ChatApp"));

const routes = [
	// { path: '/', exact: true, name: 'Home' },
	{
		path: "/Chat",
		exact: true,
		name: "Chat",
		element: Chat,
		roles: ["ADMIN", "MANAGER", "USER"],
	},
	{
		path: "/Dashboard",
		exact: true,
		name: "Dashboard",
		element: Charts,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		path: "/EmbeddedSignUp",
		name: "EmbeddedSignUp",
		element: EmbeddedSignUp,
		roles: ["ADMIN"],
	},
	{
		path: "/Schedules/NewSchedule",
		name: "New Schedules",
		element: Schedules,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		path: "/CustomerData",
		name: "CustomerData",
		element: CustomerData,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		path: "/CustomerData/uploadUsers",
		name: "uploadUsers",
		element: UploadUsers,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		path: "/Schedules",
		name: "Schedules",
		element: Scheduling,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		path: "/Schedules/EditSchedule",
		name: "Edit Schedules",
		element: EditSchedule,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		path: "/CsvHeaders",
		name: "CSV Headers",
		element: CsvHeaders,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		path: "/MessageTemplate",
		name: "Message Templates",
		element: MessageTemplate,
		roles: ["ADMIN", "MANAGER", "USER"],
	},
	{
		path: "/MessageTemplate/View",
		name: "View Message Templates",
		element: ViewMessageTemplate,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		path: "/MessageTemplate/Create",
		name: "View Message Templates",
		element: CreateMessageTemplate,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		path: "/MessageTemplate/Edit",
		name: "View Message Templates",
		element: CreateMessageTemplate,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		path: "/ScheduleFlow",
		name: "Schedule Flows",
		element: ScheduleFlow,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		path: "/ScheduleFlow/Create",
		name: "Create Schedule Flow",
		element: CreateScheduleFlow,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		path: "/ScheduleFlow/AssignUsers",
		name: "Assign Users",
		element: AssignUsers,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		path: "/ScheduleFlow/AssignFlow",
		name: "Assign Flow",
		element: AssignFlow,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		path: "/ScheduleFlow/AssignedFlows",
		name: "Assigned Flows",
		element: AssignedFlows,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		path: "/ScheduleFlow/AssignedFlows/Edit",
		name: "Edit Assigned Flows",
		element: EditAssignFlow,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		path: "/Home/Settings/Account",
		name: "Account Settings",
		element: AccountSettings,
		icon: UserCircle,
		roles: ["ADMIN", "MANAGER", "USER"],
		isSettings: true,
	},
	{
		path: "/Home/Settings/CustomMessages",
		name: "Custom Messages Settings",
		element: CustomMessagesSettings,
		icon: MessageSquare,
		roles: ["ADMIN"],
		isSettings: true,
	},
	{
		path: "/Home/Settings/ManageUsers",
		name: "Manage Users Settings",
		element: UserManagement,
		icon: UserCog,
		roles: ["ADMIN"],
		isSettings: true,
	},
];

export default routes;
