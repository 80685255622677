import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./Components/Login/Login";
import RegistrationForm from "./Components/Register/Register";
import NoPage from "./Components/NoPageFound/NoPage";
import TokenValidationPage from "./Components/TokenValidation/TokenValidation";
import SignUpForm from "./Components/SignUp/Signup";
import DefaultLayout from "./Components/layout/DefaultLayout";
import "../src/Components/scss/style.scss";
import { Toaster } from "react-hot-toast";
import UserSignup from "./Components/SignUp/UserSignup";
import ErrorPage from "./Components/views/pages/Error";
import { AuthProvider } from "./Components/AuthProvider/AuthProvider";
import ProtectedRoute from "./ProtectedRoute";

function App() {
	return (
		<div className="font-[nunito]">
			<div>
				<Toaster toastOptions={{ duration: 10000 }} />
			</div>
			<BrowserRouter>
				<AuthProvider>
					<Routes>
						<Route exact path="/signup" element={<RegistrationForm />} />
						<Route element={<ProtectedRoute />}>
							<Route path="/Home/*" element={<DefaultLayout />} />
						</Route>
						<Route exact path="/" element={<LoginPage />} />
						<Route exact path="/adminsignup/:token" element={<SignUpForm />} />
						<Route
							exact
							path="/debugToken/:token"
							element={<TokenValidationPage />}
						/>
						<Route exact path="/usersignup/:token" element={<UserSignup />} />
						<Route path="/error" element={<ErrorPage />} />
						<Route path="*" element={<NoPage />} />
					</Routes>
				</AuthProvider>
			</BrowserRouter>
		</div>
	);
}

export default App;
