import { useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "src/Components/AuthProvider/AuthProvider";
import { Button } from "src/Components/ui/Button";
import { ScrollArea } from "src/Components/ui/ScrollArea";
import routes from "../routes";
import { useSidebar } from "../ui/Sidebar";

export default function SettingsLayout() {
	const location = useLocation();
	const navigate = useNavigate();
	const { setOpen } = useSidebar();
	const initializedRef = useRef(false);
	const { role } = useAuth().userDetails;

	useEffect(() => {
		if (!initializedRef.current) {
			setOpen(false);
			initializedRef.current = true;
		}
	}, [setOpen]);

	const settingsRoutes = routes.filter(
		(route) => route.isSettings && route.roles.includes(role.toUpperCase()),
	);

	return (
		<div className="h-full overflow-hidden rounded-lg bg-white font-[nunito] shadow-lg">
			<div className="flex h-full flex-col md:flex-row">
				<div className="bg-gray-50 p-4 md:w-1/4">
					<nav className="space-y-1">
						{settingsRoutes.map((route) => {
							const Icon = route.icon;
							return (
								<Button
									key={route.path}
									variant={
										location.pathname === route.path ? "default" : "ghost"
									}
									className={`w-full justify-start text-left ${
										location.pathname === route.path
											? "bg-blue-500 text-white hover:bg-blue-600"
											: "text-gray-600 hover:bg-gray-200 hover:text-gray-900"
									}`}
									onClick={() => navigate(route.path)}
								>
									<Icon className="mr-2 h-5 w-5" />
									{route.name}
								</Button>
							);
						})}
					</nav>
				</div>
				<div className="border-l border-gray-200 bg-blue-50 md:w-3/4">
					<ScrollArea className="h-[calc(100vh-10rem)]">
						<div className="p-6">
							<Outlet />
						</div>
					</ScrollArea>
				</div>
			</div>
		</div>
	);
}
