import { CaretSortIcon } from "@radix-ui/react-icons";
import { ChevronRight, FileCog, LogOut, Settings, User } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import navItems from "../_nav";
import logo from "../assets/logo.png";
import { useAuth } from "../AuthProvider/AuthProvider";
import { Avatar, AvatarFallback } from "../ui/Avatar";
import { Button } from "../ui/Button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "../ui/Dropdown";
import { ScrollArea } from "../ui/ScrollArea";
import {
	Sidebar,
	SidebarContent,
	SidebarHeader,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
	SidebarTrigger,
	useSidebar,
} from "../ui/Sidebar";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/Tooltip";

export default function AppSidebar() {
	const { role } = useAuth().userDetails;

	const filterNavItems = (items) => {
		return items
			.map((item) => {
				if (!item.roles) return item;
				if (!item.roles.includes(role.toUpperCase())) return null;
				if (item.subfolders) {
					const filteredSubfolders = item.subfolders.filter(
						(subfolder) =>
							!subfolder.roles || subfolder.roles.includes(role.toUpperCase()),
					);

					if (filteredSubfolders.length === 0) return null;

					return {
						...item,
						subfolders: filteredSubfolders,
					};
				}

				return item;
			})
			.filter(Boolean);
	};
	const filteredNavItems = filterNavItems(navItems);
	return (
		<Sidebar
			collapsible="icon"
			className="dark min-w-[3.5rem] font-[nunito] text-white"
		>
			<SidebarHeader className="flex flex-col items-center justify-between">
				<SidebarMenu className="mb-4 flex w-full items-center justify-center">
					<img src={logo} alt="Logo" className="w-24 object-contain" />
				</SidebarMenu>
			</SidebarHeader>
			<SidebarContent className="flex h-[calc(100%-8rem)] flex-col gap-3">
				<ScrollArea>
					<SidebarMenu className="flex flex-col gap-2">
						{filteredNavItems.map((folder, index) => (
							<RecursiveFolderItem key={index} folder={folder} level={0} />
						))}
					</SidebarMenu>
				</ScrollArea>
				<div className="mt-auto pb-4">
					<ProfileMenu />
				</div>
			</SidebarContent>
		</Sidebar>
	);
}

function RecursiveFolderItem({ folder, level }) {
	const [isOpen, setIsOpen] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const { open } = useSidebar();
	const location = useLocation();

	useEffect(() => {
		if (!open) {
			setIsOpen(false);
		}
	}, [open]);

	const toggleFolder = (e) => {
		if (folder.subfolders && folder.subfolders.length > 0) {
			e.preventDefault();
			e.currentTarget.blur();
			setIsOpen(!isOpen);
		}
	};

	return (
		<SidebarMenuItem className="relative">
			<div
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<Tooltip delayDuration={0}>
					<TooltipTrigger asChild>
						<Link
							to={folder.to}
							className="w-full py-4"
							onClick={(e) => {
								if (!folder.subfolders?.length) {
									e.currentTarget.blur();
								}
							}}
						>
							<SidebarMenuButton
								onClick={toggleFolder}
								className={`w-full overflow-x-hidden border-none py-4 ${
									level > 0 ? `pl-${level * 2}` : ""
								} ${location.pathname === folder.to ? "bg-zinc-700" : ""}`}
							>
								<div
									className={`flex items-center ${level > 0 ? `ml-${level * 2}` : ""}`}
								>
									<folder.icon className="h-6 w-6 shrink-0" />
									<span className="ml-2 truncate">{folder.name}</span>
								</div>
								{folder.subfolders && folder.subfolders.length > 0 && (
									<ChevronRight
										className={`ml-auto h-4 w-4 shrink-0 transition-transform ${
											isOpen ? "rotate-90 transform" : ""
										}`}
									/>
								)}
							</SidebarMenuButton>
						</Link>
					</TooltipTrigger>
					{!open && isHovered && (
						<TooltipContent side="right" className="bg-zinc-800 text-white">
							{folder.name}
						</TooltipContent>
					)}
				</Tooltip>
			</div>
			{isOpen && folder.subfolders && folder.subfolders.length > 0 && (
				<>
					<div
						className={`absolute bottom-0 left-4 top-8 w-px bg-gray-600 ${
							level > 0 ? `ml-${level * 2}` : ""
						}`}
					/>
					<SidebarMenu className="mt-1">
						{folder.subfolders.map((subfolder, index) => (
							<RecursiveFolderItem
								key={index}
								folder={subfolder}
								level={level + 1}
							/>
						))}
					</SidebarMenu>
				</>
			)}
		</SidebarMenuItem>
	);
}

const profileMenuItems = [
	{
		icon: User,
		name: "Account",
		to: "/Home/Profile",
		roles: ["USER", "ADMIN", "MANAGER"],
	},
	{
		icon: Settings,
		name: "Settings",
		to: "/Home/Settings",
		roles: ["ADMIN", "MANAGER", "USER"],
	},
	{
		name: "Manage Headers",
		to: "/Home/CsvHeaders",
		icon: FileCog,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		icon: LogOut,
		name: "Logout",
		action: "logout",
		roles: ["USER", "ADMIN", "MANAGER"],
	},
];

function ProfileMenu() {
	const { open } = useSidebar();
	const [isHovered, setIsHovered] = useState(false);
	const { name, waBaName, role } = useAuth().userDetails;
	const navigate = useNavigate();

	const handleMenuItemClick = (item) => {
		if (item.action === "logout") {
			localStorage.clear();
			navigate("/");
			window.location.reload();
		} else if (item.to) {
			navigate(item.to);
		}
	};

	return (
		<div className="flex flex-col items-center justify-center gap-3">
			<div
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<Tooltip delayDuration={0}>
					<TooltipTrigger asChild>
						<SidebarTrigger className="border-none p-0 hover:bg-gray-200" />
					</TooltipTrigger>
					{!open && isHovered && (
						<TooltipContent side="right" className="bg-zinc-800 text-white">
							{open ? "Collapse sidebar" : "Expand sidebar"}
						</TooltipContent>
					)}
				</Tooltip>
			</div>
			<DropdownMenu className="flex w-full items-center justify-center">
				<div
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
					className="w-full"
				>
					<Tooltip delayDuration={0}>
						<TooltipTrigger asChild>
							<DropdownMenuTrigger asChild>
								<Button className="h-8 w-full border-none bg-zinc-800 px-2 py-4 text-white hover:bg-zinc-950">
									<Avatar className={`h-8 w-8 shrink-0`}>
										{/* <AvatarImage src="/placeholder.svg" alt="Profile" /> */}
										<AvatarFallback>
											{name
												?.split(" ")
												.map((word) => word[0])
												.join("")}
										</AvatarFallback>
									</Avatar>
									<span className="sr-only">Open user menu</span>
									{open && (
										<>
											<div className="ml-2 flex flex-col items-start">
												<span className="text-sm font-medium">{name}</span>
												<span className="text-xs text-muted-foreground">
													{waBaName || "No Business Name"}
												</span>
											</div>
											<CaretSortIcon className="ml-auto h-4 w-4 opacity-50" />
										</>
									)}
								</Button>
							</DropdownMenuTrigger>
						</TooltipTrigger>
						{!open && isHovered && (
							<TooltipContent side="right" className="bg-zinc-800 text-white">
								{name}
							</TooltipContent>
						)}
					</Tooltip>
					<DropdownMenuContent
						className="dark w-56 bg-[#18181b] py-3 text-white"
						align="end"
						side="right"
						sideOffset={5}
						alignOffset={-5}
						forceMount
					>
						<DropdownMenuLabel className="font-normal">
							<div className="flex flex-col space-y-1">
								<p className="text-sm font-medium leading-none text-white">
									{name}
								</p>
								<p className="text-xs leading-none text-muted-foreground">
									{waBaName}
								</p>
							</div>
						</DropdownMenuLabel>
						<DropdownMenuSeparator />
						<DropdownMenuGroup>
							{profileMenuItems
								.filter((item) => item.roles.includes(role.toUpperCase()))
								.map((item, index) => (
									<DropdownMenuItem
										key={index}
										onClick={() => handleMenuItemClick(item)}
									>
										<item.icon className="mr-2 h-4 w-4" />
										<span>{item.name}</span>
									</DropdownMenuItem>
								))}
						</DropdownMenuGroup>
					</DropdownMenuContent>
				</div>
			</DropdownMenu>
		</div>
	);
}
