import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { ROLES } from "src/lib/roleUtils";
const AuthContext = createContext();
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem("access_token");
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(true);
	const [loading, setLoading] = useState(true);
	const [userDetails, setUserDetails] = useState({
		name: "",
		email: "",
		waBaName: "",
		role: "",
		phoneNumberId: "",
	});
	const [permissions, setPermissions] = useState([]);

	const getUserInfo = async () => {
		try {
			setLoading(true);
			const config = {
				method: "get",
				url: backendUrl + "/api/v1/users/getUserInfo",
				headers: {
					Authorization: "Bearer " + localStorage.getItem("access_token"),
				},
			};

			const response = await axios.request(config);
			localStorage.setItem("userDetails", JSON.stringify(response.data));
			setUserDetails(response.data);

			setPermissions(ROLES[response.data.role].permissions);
			return response.data;
		} catch (error) {
			console.error("Error fetching user info:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const checkAuthentication = async () => {
			setLoading(true);
			if (token !== null) {
				try {
					const response = await axios.get(
						backendUrl + `/api/v1/auth/verifyJwtToken/${token}`,
					);
					if (response.data === true) {
						setIsAuthenticated(true);
						let localStorageDetails = localStorage.getItem("userDetails");
						if (localStorageDetails) {
							let details = JSON.parse(localStorageDetails);
							setUserDetails(details);
							setPermissions(ROLES[details.role].permissions);
						} else {
							await getUserInfo();
						}
					} else {
						localStorage.removeItem("access_token");
						localStorage.removeItem("userDetails");
						localStorage.removeItem("customerData");
						localStorage.removeItem("columnHeaders");
						localStorage.removeItem("refresh_token");
						setIsAuthenticated(false);
					}
				} catch (error) {
					console.error("Error verifying authentication:", error);
					setIsAuthenticated(false);
				} finally {
					setLoading(false);
				}
			} else {
				setIsAuthenticated(false);
				setLoading(false);
			}
		};

		checkAuthentication();
	}, []);

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				loading,
				userDetails,
				permissions,
				setLoading,
				setIsAuthenticated,
				getUserInfo,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
