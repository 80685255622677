import React from "react";
import { useLocation, Link } from "react-router-dom";
import {
	Breadcrumb,
	BreadcrumbList,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbPage,
	BreadcrumbSeparator,
} from "src/Components/ui/BreadCrumb";
import { Home } from "lucide-react";
import routes from "../routes";
import { getDefaultRoute } from "src/lib/roleUtils";
import { useAuth } from "../AuthProvider/AuthProvider";

const AppBreadcrumb = () => {
	const location = useLocation();
	const { role } = useAuth().userDetails;

	const getRouteName = (path) => {
		const pathParts = path.split("/").filter(Boolean);
		const fullPath = "/" + pathParts.join("/");

		const currentRoute = routes.find((route) => route.path === fullPath);
		return currentRoute ? currentRoute.name : pathParts[pathParts.length - 1];
	};

	const getBreadcrumbs = () => {
		const pathnames = location.pathname
			.split("/")
			.filter((x) => x && x !== "Home");
		const breadcrumbs = [];

		let currentPath = "/Home";
		pathnames.forEach((part, index) => {
			currentPath += `/${part}`;
			const name = getRouteName(currentPath);

			if (name) {
				breadcrumbs.push({
					name: name,
					pathname: currentPath,
					active: index === pathnames.length - 1,
				});
			}
		});

		return breadcrumbs;
	};

	const breadcrumbs = getBreadcrumbs();

	return (
		<Breadcrumb>
			<BreadcrumbList>
				<BreadcrumbItem>
					<BreadcrumbLink asChild>
						<Link to={getDefaultRoute(role)}>
							<Home className="h-4 w-4" />
						</Link>
					</BreadcrumbLink>
				</BreadcrumbItem>

				{breadcrumbs.map((breadcrumb, index) => (
					<React.Fragment key={breadcrumb.pathname}>
						<BreadcrumbSeparator />
						<BreadcrumbItem>
							{breadcrumb.active ? (
								<BreadcrumbPage>{breadcrumb.name}</BreadcrumbPage>
							) : (
								<BreadcrumbLink asChild>
									<Link to={breadcrumb.pathname}>{breadcrumb.name}</Link>
								</BreadcrumbLink>
							)}
						</BreadcrumbItem>
					</React.Fragment>
				))}
			</BreadcrumbList>
		</Breadcrumb>
	);
};

export default React.memo(AppBreadcrumb);
