import { createStore } from "redux";

const initialState = {
	sidebarShow: true,
};

// Action creator for setting sidebar visibility
export const setSidebarShow = (show) => ({
	type: "SET_SIDEBAR_SHOW",
	show, // This will be used in the reducer
});

const changeState = (state = initialState, { type, ...rest }) => {
	switch (type) {
		case "SET_SIDEBAR_SHOW":
			return { ...state, sidebarShow: rest.show };
		case "set":
			return { ...state, ...rest };
		default:
			return state;
	}
};

const store = createStore(changeState);
export default store;
