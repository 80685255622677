import { AlertCircle, Home } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "src/Components/ui/Button";
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from "src/Components/ui/Card";

export default function ErrorPage(props) {
	const location = useLocation();

	const { statusCode, title, message, from } = location.state || {};

	return (
		<div
			className={`flex ${props.title ? "h-full" : "h-screen"} items-center justify-center bg-blue-50 px-4`}
		>
			<Card className="w-full max-w-md border-blue-500 shadow-lg">
				<CardHeader className="space-y-1 text-center">
					<div className="mb-4 flex justify-center">
						<AlertCircle className="h-16 w-16 text-blue-600" />
					</div>
					<CardTitle className="text-3xl font-bold text-blue-700">
						{statusCode || props.statusCode || 500}:{" "}
						{title || props.title || "Oops! Something went wrong"}
					</CardTitle>
				</CardHeader>
				<CardContent>
					<p className="mb-6 text-center text-blue-600">
						{message ||
							props.message ||
							"We're sorry, but we're having trouble processing your request. Please try again later."}
					</p>
					<div className="flex flex-col justify-center gap-4 sm:flex-row">
						<Button
							asChild
							className="bg-blue-600 text-white hover:bg-blue-700"
						>
							<Link to={`${from || "/"}`}>
								<Home className="mr-2 h-4 w-4" />
								Go to Homepage
							</Link>
						</Button>
					</div>
				</CardContent>
				<CardFooter className="flex justify-center px-0 text-sm text-blue-500">
					If the problem persists, please contact our support team.
				</CardFooter>
			</Card>
		</div>
	);
}
