import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const TokenValidationPage = () => {
	const backendUrl = process.env.REACT_APP_BACKEND_URL;
	const { token } = useParams();
	const [loading, setLoading] = useState(true);
	const history = useNavigate();

	useEffect(() => {
		const validateToken = async () => {
			try {
				const response = await axios.get(
					backendUrl + `/api/v1/auth/verifyToken/${token}`,
				);
				if (response.data === true) {
					history(`/adminsignup/${token}`);
				} else {
					alert("Invalid or expired token");
				}
			} catch (error) {
				console.error("Error validating token:", error);
				alert("An error occurred while validating token");
			} finally {
				setLoading(false); // Update loading state after token validation completes
			}
		};

		validateToken();
	}, [token, history, backendUrl]);

	return (
		<div className="container mt-5">
			<h2>Token Validation</h2>
			{loading ? (
				<div className="spinner-border" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			) : (
				<p>Validating token...</p>
			)}
		</div>
	);
};

export default TokenValidationPage;
