import { CSpinner } from "@coreui/react";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "../../PrivateRoute";
import SettingsLayout from "../layout/SettingsLayout";
import routes from "../routes";
import NoPage from "../NoPageFound/NoPage";

const AppContent = () => {
	return (
		<div className="h-full">
			<Suspense fallback={<CSpinner color="primary" />}>
				<Routes>
					{routes.map((route, idx) => (
						<Route
							key={idx}
							path={route.path}
							element={
								<PrivateRoute allowedRoles={route.roles || []}>
									<route.element />
								</PrivateRoute>
							}
						/>
					))}

					<Route path="/Settings" element={<SettingsLayout />}>
						<Route index element={<Navigate to="Account" replace />} />
						{routes
							.filter((route) => route.isSettings)
							.map((route, idx) => (
								<Route
									key={idx}
									path={route.path.replace("/Home/Settings/", "")}
									element={
										<PrivateRoute allowedRoles={route.roles || []}>
											<route.element />
										</PrivateRoute>
									}
								/>
							))}
					</Route>

					<Route path="*" element={<NoPage />} />
				</Routes>
			</Suspense>
		</div>
	);
};

export default React.memo(AppContent);
