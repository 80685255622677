import { AppContent, AppFooter, AppHeader } from "../components/index";
import AppSidebar from "../components/AppSidebar";
import { SidebarProvider } from "../ui/Sidebar";

const DefaultLayout = () => {
	return (
		<SidebarProvider>
			<div className="flex min-h-screen w-screen">
				<AppSidebar />
				<main className="flex w-[calc(100%-3rem)] flex-col">
					<AppHeader />
					<div className="flex-grow bg-[aliceblue] px-2">
						<AppContent />
					</div>
					<AppFooter />
				</main>
			</div>
		</SidebarProvider>
	);
};

export default DefaultLayout;
