import axios from "axios";
import { Check, Eye, EyeOff, X } from "lucide-react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "src/Components/ui/Button";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "src/Components/ui/Card";
import { Input } from "src/Components/ui/Input";
import { Label } from "src/Components/ui/Label";
import logo from "../assets/logo.png";
import Loader from "../views/Loader/Loader";

function LoginForm() {
	const backendUrl = process.env.REACT_APP_BACKEND_URL;
	const { token } = useParams();
	const history = useNavigate();
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		const validateToken = async () => {
			try {
				const response = await axios.get(
					backendUrl + "/api/v1/auth/verifyToken/" + token,
				);
				if (response.data === false) {
					history("/error");
				}
			} catch (error) {
				console.error("Error validating token:", error);
				alert("An error occurred while validating token");
			} finally {
				setLoading(false);
			}
		};
		validateToken();
	}, [token, history, backendUrl]);
	const [formData, setFormData] = useState({
		firstname: "",
		lastname: "",
		email: "",
		password: "",
		role: "ADMIN",
	});
	const [showPassword, setShowPassword] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [passwordRequirements, setPasswordRequirements] = useState({
		length: false,
		uppercase: false,
		lowercase: false,
		number: false,
		symbol: false,
	});
	const [formErrors, setFormErrors] = useState({
		firstname: false,
		lastname: false,
		email: false,
		password: false,
	});

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
		if (name === "password") {
			validatePassword(value);
		} else if (name === "email") {
			setFormErrors((prev) => ({
				...prev,
				email: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
			}));
		} else {
			setFormErrors((prev) => ({
				...prev,
				[name]: !value.trim(),
			}));
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!areAllFieldsValid()) {
			toast.error("Please fill in all fields correctly");
			return;
		}
		setIsSubmitting(true);
		try {
			const response = await axios.post(
				backendUrl + "/api/v1/auth/registerAdminUser/" + token,
				formData,
			);
			const { access_token, refresh_token } = response.data;
			localStorage.setItem("access_token", access_token);
			localStorage.setItem("refresh_token", refresh_token);
			toast.success("Successfully signed up!");
			history("/Home/Dashboard");
		} catch (error) {
			toast.error(error.response.data.message);
		} finally {
			setIsSubmitting(false);
		}
	};

	const validatePassword = (value) => {
		setPasswordRequirements({
			length: value.length >= 8,
			uppercase: /[A-Z]/.test(value),
			lowercase: /[a-z]/.test(value),
			number: /[0-9]/.test(value),
			symbol: /[!@#$%^&*]/.test(value),
		});
	};

	const RequirementIcon = ({ met }) =>
		met ? (
			<Check className="h-4 w-4 text-green-500" />
		) : (
			<X className="h-4 w-4 text-red-500" />
		);

	const areAllFieldsValid = () => {
		const requiredFields = ["firstname", "lastname", "email", "password"];
		const hasEmptyFields = requiredFields.some(
			(field) => !formData[field].trim(),
		);
		const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
		const arePasswordRequirementsMet =
			Object.values(passwordRequirements).every(Boolean);

		return !hasEmptyFields && isEmailValid && arePasswordRequirementsMet;
	};

	return (
		<div className="flex h-screen items-center justify-center">
			{loading ? (
				<Loader />
			) : (
				<Card className="mx-auto w-full max-w-md border-blue-500">
					<CardHeader className="space-y-1 bg-blue-50 p-2">
						<div className="mb-4 flex justify-center">
							<img src={logo} alt="Logo" className="h-24 object-contain" />
						</div>
						<CardTitle className="text-center text-2xl font-bold text-blue-700">
							Sign Up
						</CardTitle>
						<CardDescription className="text-center text-blue-600">
							Create your admin account
						</CardDescription>
					</CardHeader>
					<CardContent className="pt-6">
						<form onSubmit={handleSubmit}>
							<div className="space-y-4">
								<div className="space-y-2">
									<Label htmlFor="firstname" className="text-blue-700">
										First Name
									</Label>
									<Input
										id="firstname"
										name="firstname"
										value={formData.firstname}
										onChange={handleChange}
										className={`border-blue-300 focus:border-blue-500 focus:ring-blue-500 ${formErrors.firstname ? "border-red-500" : ""}`}
										required
									/>
									{formErrors.firstname && (
										<p className="mt-1 text-xs text-red-500">
											First name is required
										</p>
									)}
								</div>

								<div className="space-y-2">
									<Label htmlFor="lastname" className="text-blue-700">
										Last Name
									</Label>
									<Input
										id="lastname"
										name="lastname"
										value={formData.lastname}
										onChange={handleChange}
										className={`border-blue-300 focus:border-blue-500 focus:ring-blue-500 ${formErrors.lastname ? "border-red-500" : ""}`}
										required
									/>
									{formErrors.lastname && (
										<p className="mt-1 text-xs text-red-500">
											Last name is required
										</p>
									)}
								</div>

								<div className="space-y-2">
									<Label htmlFor="email" className="text-blue-700">
										Email
									</Label>
									<Input
										id="email"
										name="email"
										type="email"
										value={formData.email}
										onChange={handleChange}
										className={`border-blue-300 focus:border-blue-500 focus:ring-blue-500 ${formErrors.email ? "border-red-500" : ""}`}
										required
									/>
									{formErrors.email && (
										<p className="mt-1 text-xs text-red-500">
											Please enter a valid email address
										</p>
									)}
								</div>

								<div className="space-y-2">
									<Label htmlFor="password" className="text-blue-700">
										Password
									</Label>
									<div className="relative">
										<Input
											id="password"
											name="password"
											type={showPassword ? "text" : "password"}
											value={formData.password}
											onChange={handleChange}
											className="border-blue-300 pr-10 focus:border-blue-500 focus:ring-blue-500"
											required
										/>
										<Button
											type="button"
											variant="ghost"
											size="icon"
											className="absolute right-2 top-1/2 -translate-y-1/2 text-blue-500 hover:text-blue-700"
											onClick={() => setShowPassword(!showPassword)}
										>
											{showPassword ? (
												<EyeOff className="h-4 w-4" />
											) : (
												<Eye className="h-4 w-4" />
											)}
										</Button>
									</div>
								</div>

								<div className="space-y-2 rounded-md bg-blue-50 p-4">
									<h3 className="mb-2 text-sm font-semibold text-blue-700">
										Password Requirements:
									</h3>
									<ul className="space-y-1">
										<li className="flex items-center">
											<RequirementIcon met={passwordRequirements.length} />
											<span className="ml-2 text-sm text-blue-600">
												At least 8 characters
											</span>
										</li>
										<li className="flex items-center">
											<RequirementIcon met={passwordRequirements.uppercase} />
											<span className="ml-2 text-sm text-blue-600">
												One uppercase letter
											</span>
										</li>
										<li className="flex items-center">
											<RequirementIcon met={passwordRequirements.lowercase} />
											<span className="ml-2 text-sm text-blue-600">
												One lowercase letter
											</span>
										</li>
										<li className="flex items-center">
											<RequirementIcon met={passwordRequirements.number} />
											<span className="ml-2 text-sm text-blue-600">
												One number
											</span>
										</li>
										<li className="flex items-center">
											<RequirementIcon met={passwordRequirements.symbol} />
											<span className="ml-2 text-sm text-blue-600">
												One symbol (!@#$%^&*)
											</span>
										</li>
									</ul>
								</div>
							</div>

							<Button
								type="submit"
								className="mt-6 w-full bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50"
								disabled={!areAllFieldsValid() || isSubmitting}
							>
								{isSubmitting ? (
									<div className="flex items-center justify-center">
										<span className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent" />
										Signing up...
									</div>
								) : (
									"Sign Up"
								)}
							</Button>
						</form>
					</CardContent>
				</Card>
			)}
		</div>
	);
}

export default LoginForm;
