// Loader.js
import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import logo from '../../assets/logo.jpg'; // Path to your logo

const Loader = ({ message = "Loading..." }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center',
    }}
  >
    <img src={logo} alt="Logo" style={{ width: '100px', marginBottom: '20px' }} />
    <CircularProgress />
    <Typography variant="h6" sx={{ marginTop: '20px' }}>
      {message}
    </Typography>
  </Box>
);

export default Loader;
