import { WhatsApp } from "@mui/icons-material";
import {
	BadgePlus,
	BookUser,
	CalendarClock,
	ChartNoAxesCombined,
	Folder,
	MessageSquare,
	MessagesSquare,
	ReceiptIndianRupee,
	View,
} from "lucide-react";

const navItems = [
	{
		name: "Dashboard",
		to: "/Home/Dashboard",
		icon: ChartNoAxesCombined,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		name: "Connect Business",
		to: "/Home/EmbeddedSignUp",
		icon: WhatsApp,
		roles: ["ADMIN"],
	},
	{
		name: "Customer Data",
		to: "/Home/CustomerData",
		icon: BookUser,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		name: "Message Template",
		to: "/Home/MessageTemplate",
		icon: MessagesSquare,
		roles: ["ADMIN", "MANAGER", "USER"],
	},
	{
		name: "Flows",
		to: "/Home/Flows",
		icon: Folder,
		roles: ["ADMIN", "MANAGER"],
		subfolders: [
			{
				name: "Create Flows",
				to: "/Home/ScheduleFlow",
				icon: BadgePlus,
				roles: ["ADMIN", "MANAGER"],
			},
			{
				name: "View Flows",
				to: "/Home/ScheduleFlow/AssignedFlows",
				icon: View,
				roles: ["ADMIN", "MANAGER"],
			},
		],
	},
	{
		name: "Schedule Messages",
		to: "/Home/Schedules",
		icon: CalendarClock,
		roles: ["ADMIN", "MANAGER"],
	},
	{
		name: "Chat",
		to: "/Home/Chat",
		icon: MessageSquare,
		roles: ["ADMIN", "MANAGER", "USER"],
	},
	{
		name: "Billing & Payment",
		to: "/Home/Payment",
		icon: ReceiptIndianRupee,
		roles: ["ADMIN"],
	},
];

export default navItems;
