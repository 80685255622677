import React from "react";

const AppFooter = () => {
	return (
		<div className="mt-auto bg-white p-2 text-center">
			<span className="ms-1">&copy; {new Date().getFullYear()} CHATYFY.</span>
		</div>
	);
};

export default React.memo(AppFooter);
