import { useEffect, useRef } from "react";

import { AppBreadcrumb } from "./index";

const AppHeader = () => {
	const headerRef = useRef();

	useEffect(() => {
		document.addEventListener("scroll", () => {
			headerRef.current &&
				headerRef.current.classList.toggle(
					"shadow-sm",
					document.documentElement.scrollTop > 0,
				);
		});
	}, []);

	return (
		<div
			className="sticky flex w-full items-center justify-center bg-white py-3"
			ref={headerRef}
		>
			<div className="container-fluid px-4">
				<AppBreadcrumb />
			</div>
		</div>
	);
};

export default AppHeader;
