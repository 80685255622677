export const PERMISSIONS = {
	READ: "READ",
	WRITE: "WRITE",
	EXECUTE: "EXECUTE",
	ADMIN: "ADMIN",
};

export const ROLES = {
	ADMIN: {
		name: "Admin",
		permissions: Object.freeze([
			PERMISSIONS.READ,
			PERMISSIONS.WRITE,
			PERMISSIONS.EXECUTE,
			PERMISSIONS.ADMIN,
		]),
	},
	MANAGER: {
		name: "Manager",
		permissions: Object.freeze([
			PERMISSIONS.READ,
			PERMISSIONS.WRITE,
			PERMISSIONS.EXECUTE,
		]),
	},
	USER: {
		name: "User",
		permissions: Object.freeze([PERMISSIONS.READ, PERMISSIONS.WRITE]),
	},
};

export const getDefaultRoute = (userRole) => {
	switch (userRole.toUpperCase()) {
		case "ADMIN":
		case "MANAGER":
			return "/Home/Dashboard";
		case "USER":
			return "/Home/Chat";
		default:
			return "/Home/Chat";
	}
};
